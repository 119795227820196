import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { formatString } from "../utilities";


const SelfieMasks = () => {

    const [masks, setMasks] = useState([]);

    

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/selfie-mask`, { method: 'GET' })
            .then((res) => res.json())
            .then((json) => {
                console.log(json);
                setMasks(json);
            });
    }, [])

    return (
        <>
            <div className="content-header pb-6 flex justify-between">
                <div className="content-header-label">Selfie Masks</div>
                <div className="content-header-actions">
                </div>
            </div>
            <div className="content-body">
                <table className="table-auto border w-full">
                    <thead>
                        <tr>
                            <th className="font-bold p-2 border-b text-left w-2">No</th>
                            <th className="font-bold p-2 border-b text-left w-full">Map Name</th>
                            <th className="font-bold py-2 px-4 border-b text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {masks.map((mask, i) => <tr key={i}>
                            <td className="p-2 border-b text-left">{i + 1}</td>
                            <td className="p-2 border-b text-left">{formatString(mask.mask_name)}</td>
                            <td className="py-2 px-4 border-b text-left"> <Link to={`/selfie-mask/${mask.mask_id}`}>Edit</Link></td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </>

    )
}

export default SelfieMasks;