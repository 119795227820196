import { useEffect, useState } from "react";
import { TextField, FormControl, InputLabel, Select, MenuItem, Button, IconButton } from "@mui/material";
import { HiMiniLanguage } from "react-icons/hi2";
import { useParams } from "react-router-dom";
import { createPortal } from 'react-dom';

import PopLanguages from './../components/PopLanguages';

const FeedbackQuestion = () => {

    const { question_id } = useParams();
    const [question, setQuestion] = useState(null);
    const [languages, setLanguages] = useState(null);
    const [currentLangField, setCurrentLangField] = useState(null);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setQuestion(q => { return { ...q, [name]: value } })
    }

    const handleLabelInput = (e) => {
        const { name, value } = e.target;
        setQuestion(q => { return { ...q, question_data: { ...q.question_data, [name]: value } } })
    }

    const onAnswerTypeChange = (e) => {
        setQuestion(q => { return { ...q, answer_type: e.target.value } })
    }

    useEffect(() => {

        const loadQuestion = async () => {
            await fetch(`${process.env.REACT_APP_API_URL}/question/${question_id}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
                .then((res) => res.json())
                .then((json) => {
                    setQuestion(json.question)
                })
        }

        loadQuestion();

    }, [question_id])


    const onLangPop = (field) => {
        setCurrentLangField(field)

        let ls = [];

        if (field === 'title') {
            ls = question.question_data?.title_ls || [];
        } else if (field === 'question') {
            ls = question.question_data?.question_ls || [];
        } else if (field === 'min_label') {
            ls = question.question_data?.min_label_ls || [];
        } else if (field === 'max_label') {
            ls = question.question_data?.max_label_ls || [];
        }

        setLanguages(ls);
    }

    const onSetLs = (langs) => {

        if (currentLangField === 'title') {
            setQuestion(prev => { return { ...prev, question_data: { ...question.question_data, title_ls: langs } } })
        } else if (currentLangField === 'question') {
            setQuestion(prev => { return { ...prev, question_data: { ...question.question_data, question_ls: langs } } })
        } else if (currentLangField === 'min_label') {
            setQuestion(prev => { return { ...prev, question_data: { ...question.question_data, min_label_ls: langs } } })
        } else if (currentLangField === 'max_label') {
            setQuestion(prev => { return { ...prev, question_data: { ...question.question_data, max_label_ls: langs } } })
        }

        setCurrentLangField(null);
        setLanguages(null)
    }

    const onLangPopClose = () => {
        setLanguages(null)
    }

    const onSaveQuestion = () => {
        fetch(`${process.env.REACT_APP_API_URL}/question`, {
            method:'PUT',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify(question)
        })
        .then(res => res.json())
        .then(json => {
            console.log(json);
        })
    }

    return (
        <>
            {question &&
                <div className="p-5">
                    <div className="flex flex-row mb-5">
                        <TextField
                            label="Title"
                            fullWidth
                            value={question.title}
                            onChange={handleInput}
                            name="title"
                        />
                        <IconButton aria-label="Add space name in other languages">
                            <HiMiniLanguage className='l-icon' onClick={() => onLangPop('title')} />
                        </IconButton>
                    </div>
                    <div className="flex flex-row mb-5">
                        <TextField
                            label="Question"
                            fullWidth
                            value={question.question}
                            onChange={handleInput}
                            name="question"
                        />
                        <IconButton aria-label="Add space name in other languages">
                            <HiMiniLanguage className='l-icon' onClick={() => onLangPop('question')} />
                        </IconButton>
                    </div>
                    <div className="flex flex-row pb-5  md-5">
                        <FormControl className="!mr-5 w-60">
                            <InputLabel id="answer_type">Answer Type</InputLabel>
                            <Select
                                labelId="answer_type"
                                value={question.answer_type || 0}
                                label="Answer Type"
                                onChange={onAnswerTypeChange}
                            >
                                <MenuItem value={0}>Select Type</MenuItem>
                                <MenuItem value={'rating'}>Rating</MenuItem>
                                <MenuItem value={'input_text'}>Input Text</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label="Min Label"
                            name='min_label'
                            value={question.question_data?.min_label || ""}
                            onChange={handleLabelInput}
                        />
                        <IconButton aria-label="Add space name in other languages">
                            <HiMiniLanguage className='l-icon' onClick={() => onLangPop('min_label')} />
                        </IconButton>
                        <TextField
                            name="max_label"
                            label="Max Label"
                            value={question.question_data?.max_label || ""}
                            onChange={handleLabelInput}
                        />
                        <IconButton aria-label="Add space name in other languages">
                            <HiMiniLanguage className='l-icon' onClick={() => onLangPop('max_label')} />
                        </IconButton>
                    </div>
                    <div className="flex flex-row">
                        <Button variant="contained" onClick={onSaveQuestion}>Save</Button>
                    </div>
                </div>}
            {languages && createPortal(<PopLanguages fieldName={`Space Name`} languages={languages} onSetLs={onSetLs} onLangPopClose={onLangPopClose} />, document.getElementById('modal'))}
        </>
    )
}

export default FeedbackQuestion;