import { TextField, Button } from "@mui/material"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const PopAddQuestion = ({onClose}) => {

    const navigate = useNavigate();
    const [question, setQuestion] = useState({title:"", question:""})

    const handleInput = (e) => {
        const {name, value} = e.target;
        setQuestion(q => { return { ...q, [name]:value }})
    }

    const onAddQuestion = async (e) => {
        await fetch(`${process.env.REACT_APP_API_URL}/question`, {
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify(question)
        })
        .then(res=>res.json())
        .then(json => {
            onClose(e);
            navigate(`/feedback-question/${json.question_id}`)
        })
    }

    return (
        <>
            <div className="pop-background">

            </div>

            <div className="pop-container">
                <div className="pop-header">
                    Add New Question
                </div>
                <div className="pop-content flex flex-col py-5 w-96">
                    <TextField
                        label="Title"
                        value={question.title}
                        onChange={handleInput}
                        className="!mb-5"
                        name='title'
                    />
                    <TextField
                        multiline
                        rows={3}
                        label="Question"
                        value={question.question}
                        onChange={handleInput}
                        name='question'
                    />
                </div>
                <div className="pop-footer">
                    <Button onClick={onAddQuestion}>Add</Button>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
            </div>
        </>
    )

}

export default PopAddQuestion;