import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import PopAddQuiz from "../components/PopAddQuiz";

const TreasureHunt = () => {

    const [clues, setClues] = useState([]);
    const [popAddQuiz, setPopAddMap] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        
        fetch(`${process.env.REACT_APP_API_URL}/clue`, {method: 'GET'})
        .then(res => res.json())
        .then(data => setClues(data.clues))

    }, [])

    const onAddQuiz = (e, clue_id) => {
        setPopAddMap(false);
        navigate(`/treasure/${clue_id}`);
    }

    const onCloseAddQuizPop = () => {
        setPopAddMap(false);
    }

const onOpenPopAddQuiz = () => {
    setPopAddMap(true);
}

    return (
        <>
            <div className="content-header pb-6 flex justify-between">
                    <div className="content-header-label">Spaces</div>
                    <div className="content-header-actions">
                        <Button onClick={onOpenPopAddQuiz}>Add New Quiz</Button>
                    </div>
                </div>
                <div className="content-body">

                    <table className="table-auto border w-full">
                        <thead>
                            <tr>
                                <th className="font-bold p-2 border-b text-left w-2">No</th>
                                <th className="font-bold p-2 border-b text-left">Question</th>
                                <th className="font-bold p-2 border-b text-left w-40">Question Type</th>
                                <th className="font-bold py-2 px-4 border-b text-left w-40">Actions</th>
                            </tr>
                        </thead>
                        <tbody>

                            {clues.map((clue, index) => <tr key={index}>
                                <td className="p-2 border-b text-left">{index + 1}</td>
                                <td className="p-2 border-b text-left">{clue.clue_question}</td>
                                <td className="p-2 border-b text-left">{clue.clue_type}</td>
                                <td className="py-2 px-4 border-b text-left"> <Link to={`/treasure/${clue.clue_id}`}>Edit</Link> / <Button onClick={(e) => {}}>Delete</Button> </td>
                            </tr>)}

                        </tbody>
                    </table>
                </div>

                {popAddQuiz && <PopAddQuiz onClose={onCloseAddQuizPop} onAddQuiz={onAddQuiz}/>}
        </>
    )
}

export default TreasureHunt;