const ModelPage = () => {
    return(
        <>
        Model Page
        </>
    )
}

export default ModelPage;

//