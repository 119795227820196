import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ModelsPage = () => {

    const [models, setModels] = useState([]);


    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_URL}/model`, {method:'GET'})
        .then(res => res.json())
        .then(data => setModels(data));

    }, [])


    return(
        <>
        <div className="content-header pb-6 flex justify-between">
                    <div className="content-header-label">Model</div>
                    <div className="content-header-actions">
                        <Button onClick={()=>{}}>Add New Floor</Button>
                    </div>
                </div>
                <div className="content-body">

                    <table className="table-auto border w-full">
                        <thead>
                            <tr>
                                <th className="font-bold p-2 border-b text-left w-2">No</th>
                                <th className="font-bold p-2 border-b text-left w-full">Floor</th>
                                <th className="font-bold py-2 px-4 border-b text-left">Actions</th>
                            </tr>
                        </thead>
                        <tbody>

                            {models.map((model, i) => <tr key={i}>
                                <td className="p-2 border-b text-left">{i + 1}</td>
                                <td className="p-2 border-b text-left">{model.model_name}</td>
                                <td className="py-2 px-4 border-b text-left"> <Link to={`/model/${model.model_id}`}>Edit</Link> / <Button >Delete</Button> </td>
                            </tr>)}

                        </tbody>
                    </table>
                </div>
        </>
    )
}

export default ModelsPage;

//