import { Button, TextField } from "@mui/material";
import "./../styles/PopLanguages.scss";
import { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

const PopLanguagesHtml = ({ fieldName, languages, onSetLs, onLangPopClose }) => {
  
  const [langs, setLangs] = useState(languages);

  const [availableLangs, setAvailableLangs] = useState([]);



  const onChangeLang = (newContent, lang) => {
    setLangs(prevLangs => {
        return {...prevLangs, [lang]:newContent.replace(/\n/g, "")}
    })
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/language`, { method: 'GET' })
    .then((res) => res.json())
    .then((json) => {
        console.log(json);  
        setAvailableLangs(json.languages)      
    });
  }, [])

  return (
    <div open id="popup-languages">
      <div className="pop-background"></div>
      <div className="pop-container">
        <div className="pop-header">Input data in other languages</div>
        <div className="pop-body">
          {availableLangs.map((l) => <>
          <br />
          <h3>{l.language}</h3>
          <Editor
                apiKey='xdhyjw8vq509r30xvh0rb50ymn2ytxczq9i6073asio80z92'
                init={{
                  height:300,
                    plugins: [
                        // Core editing features
                        'anchor', 'autolink', 'link', 'lists', 'searchreplace', 'visualblocks', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks fontsize | bold italic underline strikethrough | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | removeformat'                    
                }}
                value={langs[l.language_slug] || ""}
                onEditorChange={(newContent) => { onChangeLang(newContent, l.language_slug)}}
            />
          <br />
          </>
          )}
          <br />
        </div>
        <div className="pop-footer">
          <Button
            variant="contained"
            className="!mr-6"
            onClick={() => onSetLs(langs)}
          >
            Update and Close
          </Button>
          <Button onClick={onLangPopClose}>Cancel</Button>
        </div>
      </div>
    </div>
  );
};

export default PopLanguagesHtml;
