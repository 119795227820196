import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { Button, IconButton } from "@mui/material";
import { HiMiniLanguage } from "react-icons/hi2";
import { createPortal } from 'react-dom';

import PopLanguages from './../components/PopLanguages';
import PopLanguagesHtml from "../components/PopLanguagesHtml";

const InfoPage = () => {

    const { page_id } = useParams();

    const [content, setContent] = useState({});
    const [langPop, setLangPop] = useState(false);


    const formatText = (text) => {
        return text.replace(/-/g, ' ') // Replace hyphens with spaces
            .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize each word
    }

    const handleChange = (newContent) => {
        setContent(c => { return { ...c, english: newContent.replace(/\n/g, "") } })

    }



    const onSaveChanges = () => {
        const payload = {
            key: page_id,
            value: content
        }

        fetch(`${process.env.REACT_APP_API_URL}/setting`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        })
            .then(res => res.json())
            .then(json => {
                console.log(json);
            })
    }

    const onOpenPop = () => {
        setLangPop(true);
    }

    const onClosePop = () => {
        setLangPop(false);
    }

    const setLangs = (langs) => {
        console.log(langs);
        setContent(langs);
        setLangPop(false);
    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/setting/${page_id}`, { method: 'GET' })
            .then((res) => res.json())
            .then((json) => {
                console.log(json.data.svalue);
                setContent(json.data.svalue)
            })
    }, []);

    return (
        <div className="p-5">
            <div className="font-bold">
                {formatText(page_id)}
            </div>
            {<Editor
                apiKey='xdhyjw8vq509r30xvh0rb50ymn2ytxczq9i6073asio80z92'
                init={{
                    plugins: [
                        // Core editing features
                        'anchor', 'autolink', 'link', 'lists', 'searchreplace', 'visualblocks', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks fontsize | bold italic underline strikethrough | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | removeformat',
                    forced_root_block: "",  // Avoid automatic <p> tags or <br>
                    convert_newlines_to_brs: false, // Prevent <br> insertion for newlines
                }}
                value={content.english}
                onEditorChange={handleChange}
            />}
            <IconButton aria-label="Add space name in other languages">
                            <HiMiniLanguage className='l-icon' onClick={onOpenPop} />
                        </IconButton>
            <Button variant="contained" onClick={onSaveChanges}>Save</Button>
            {langPop && createPortal(<PopLanguagesHtml fieldName={`Space Name`} languages={content} onSetLs={setLangs} onLangPopClose={onClosePop} />, document.getElementById('modal'))}
        </div>
    )
}

export default InfoPage;