import { useState, useEffect } from "react";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"

import "./../styles/PopAddSpace.scss";


const PopAddQuiz = ({onClose, onAddQuiz}) => {

    const [clue, setClue] = useState({clue_question:"", clue_type:""});


    const onChangeQuiz = (e) => {
        const quiz = e.target.value;

        setClue({...clue, clue_question: quiz});

    }

    const onChangeType = (e) => {
        const type = e.target.value;

        setClue({...clue, clue_type: type});
    }


    const onAddClue = (e) => {

        fetch(`${process.env.REACT_APP_API_URL}/clue`, {
            method: "POST",
            body: JSON.stringify(clue),
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then((res) => res.json())
        .then(json => {
            onAddQuiz(e, json.clue_id);
        }).catch(err => {
            console.log(err)
        })        

    }


    return (
        <>
            <div className="pop-background">

            </div>

            <div className="pop-container">
                <div className="pop-header">
                    Add New Quiz
                </div>
                <div className="pop-content flex flex-col gap-4 w-[600px]">
                    <TextField
                        label="Quiz"
                        value={clue.clue_question}
                        onChange={onChangeQuiz}
                    />
                    <FormControl className="!mr-5 w-60">
                        <InputLabel id="answer_type">Question Type</InputLabel>
                        <Select
                            labelId="question_type"
                            value={0}
                            label="Question Type"
                            onChange={onChangeType}
                        >
                            <MenuItem value={0}>Select Type</MenuItem>
                            <MenuItem value={'question'}>Question</MenuItem>
                            <MenuItem value={'puzzle'}>Puzzle</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="pop-footer">
                    <Button onClick={onAddClue}>Add</Button>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
            </div>            
        </>
    )
}

export default PopAddQuiz;