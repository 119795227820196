import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const InfoPages = () => {
    return (
        <div className="m-5 grid grid-cols-2 gap-4 p-4 max-w-[600px]">
            <Button variant="contained" component={Link} to={'/page/about'}>About</Button>
            <Button variant="contained" component={Link} to={'/page/terms-and-conditions'}>Terms and Conditions</Button>
            <Button variant="contained" component={Link} to={'/page/privacy-policy'}>Privacy Policy</Button>
            <Button variant="contained" component={Link} to={'/page/data-policy'}>Data Policy</Button>
        </div>
    )
}

export default InfoPages;