import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PopAddQuestion from "../components/PopAddQuestion";

const FeedbackQuestions = () => {

    const [questions, setQuestions] = useState([]);
    const [newQuestionPop, setNewQuestionPop] = useState(false);

    const onOpenNewQuestionPop = (e) => {
        setNewQuestionPop(true);
    }

    const onCloseNewQuestionPop = (e) => {
        setNewQuestionPop(false);
    }

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_URL}/question`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => res.json())
            .then(json => {
                setQuestions(json.questions);
            })
    }, []);

    return (
        <>
            <div className="content-header pb-6 flex justify-between">
                <div className="content-header-label">Feedback Questions</div>
                <div className="content-header-actions">
                    <Button variant="contained" onClick={onOpenNewQuestionPop}>Add New Question</Button>
                </div>
            </div>
            <div className="content-body">
                <table class="table-auto border-collapse border border-gray-300 w-full text-left">
                    <thead class="bg-gray-100">
                        <tr>
                            <th class="border border-gray-300 px-4 py-2 w-16">No</th>
                            <th class="border border-gray-300 px-4 py-2 w-48">Question Title</th>
                            <th class="border border-gray-300 px-4 py-2">Question</th>
                            <th class="border border-gray-300 px-4 py-2 w-32">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {questions.map((q, i) => <tr key={i}>
                            <td class="border border-gray-300 px-4 py-2">{i+1}</td>
                            <td class="border border-gray-300 px-4 py-2">{q.title}</td>
                            <td class="border border-gray-300 px-4 py-2">{q.question}</td>
                            <td class="border border-gray-300 px-4 py-2">
                                <Link to={`/feedback-question/${q.question_id}`}>Edit</Link>
                                <button class="text-red-500 hover:underline ml-2">Delete</button>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
            { newQuestionPop && <PopAddQuestion onClose={onCloseNewQuestionPop}/> }
        </>
    )
}

export default FeedbackQuestions;