import { createBrowserRouter, Navigate } from "react-router-dom";
import Login from "./views/Login";
import NotFound from "./views/NotFound";
import DefaultLayout from "./components/DefaultLayout";
import GuestLayout from "./components/GuestLayout";
import Dashboard from "./views/Dashboard";
import Space from "./views/Space";
import Spaces from "./views/Spaces";
import Maps from "./views/Maps";
import Map from "./views/Map";
import Labels from "./views/Labels";
import Label from "./views/Label";
import FeedbackQuestions from "./views/FeedbackQuestions";
import FeedbackQuestion from "./views/FeedbackQuestion";
import InfoPages from "./views/InfoPages";
import InfoPage from "./views/InfoPage";
import TreasureHunt from "./views/TreasureHunt";
import TreasureClue from "./views/TreasureClue";
import ModelsPage from "./views/ModelsPage";
import ModelPage from "./views/ModelPage";
import SelfieMasks from "./views/SelfieMasks";
import SelfieMask from "./views/SelfieMask";


const router = createBrowserRouter([
    {   
        path:'/',
        element: <DefaultLayout/>,
        children:[
            {
                path:'/',
                element: <Navigate to={'/spaces'}/>
            },
            {
                path:'/dashboard',
                element: <Dashboard/>
            },
            {
                path:'/spaces',
                element:<Spaces/>
            }
            ,
            {
                path:'/space/:space_id',
                element:<Space/>
            },
            {
                path:'/maps',
                element:<Maps/>
            }
            ,
            {
                path:'/map/:map_id',
                element:<Map/>
            },
            {
                path:'/labels',
                element:<Labels/>
            }
            ,
            {
                path:'/label/:label_id',
                element:<Label/>
            },
            {
                path:'/feedback-question',
                element:<FeedbackQuestions/>
            }
            ,
            {
                path:'/feedback-question/:question_id',
                element:<FeedbackQuestion/>
            },
            {
                path:'/pages',
                element:<InfoPages/>
            },
            {
                path:'/page/:page_id',
                element:<InfoPage/>
            },
            {
                path:'/treasure',
                element:<TreasureHunt/>
            },
            {
                path:'/treasure/:clue_id',
                element:<TreasureClue/>
            },
            {
                path:'/models',
                element:<ModelsPage/>
            },
            {
                path:'/model/:model_id',
                element:<ModelPage/>
            },
            {
                path:'/selfie-mask',
                element:<SelfieMasks/>
            },
            {
                path:'/selfie-mask/:mask_id',
                element:<SelfieMask/>
            },
        ]
    },
    {
        path:'/',
        element: <GuestLayout/>,
        children:[
            {
                path:'/login',
                element:<Login/>
            }
        ]

    },
    {
        path:'*',
        element:<NotFound/>
    }
]);

export default router;