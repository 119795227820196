import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createPortal } from "react-dom";

import { TextField, IconButton, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import { HiMiniLanguage } from "react-icons/hi2";
import  CheckBox  from "@mui/material/Checkbox";

import { ASSETS_PATH } from "../constants";
import axiosClient from '../axios-client';

import PopLanguages from "../components/PopLanguages";
import CircularWithValueLabel from '../components/CircularWithValueLabel';

const TreasureClue = () => {

    const { clue_id } = useParams();

    const [clue, setClue] = useState(null);

    const [currentLangField, setCurrentLangField] = useState(null);
    const [currentLangFieldIndex, setCurrentLangFieldIndex] = useState(null);
    const [languages, setLanguages] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);


    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/clue/${clue_id}`, { method: 'GET' })
            .then(res => res.json())
            .then(data => {
                
                if(!data.clue.clue_question_ls){
                    data.clue.clue_question_ls = {}
                }

                if(!data.clue.clue_options){
                    data.clue.clue_options = new Array();
                }

                console.log(data)

                setClue(data.clue);
            })
            .catch(err => {
                console.log(err);
            })
    }, [clue_id])



    const onChangeQuestion = (e) => {
        setClue(prev => { return { ...prev, clue_question: e.target.value } });
    }


    const onChangeType = (e) => {
        setClue(prev => { return { ...prev, clue_type: e.target.value } });
    }

    const onChangeTrigger = (e) => {
        setClue(prev => { return { ...prev, clue_trigger: e.target.value } });
    }

    const onChangeAnswer = (e, index) => {
        setClue((prev) => {
            prev.clue_options[index].answer = e.target.value;
            return { ...prev }
        })
    }


    const onAddOption = () => {

        console.log(clue.clue_options);


        if(clue.clue_options.length >= 3){
            alert("You have reached the maximum number of options");
            return;
        }

        setClue(prev => {

            const p = {...prev}

            p.clue_options.push({answer: '', correct_answer: false, answer_ls: {}})

            return(p);

        })
    }

    const removeOption = (e, index) => {
        
        setClue(prev => {

            const p = {...prev}

            p.clue_options.splice(index, 1);

            return(p);

        })

    }

    const onChangeCorrect = (e, index) => {
        setClue((prev) => {
            prev.clue_options[index].correct_answer = e.target.checked;
            return { ...prev }
        })
    }


    const onLangPop = (field, index=null) => {
        setCurrentLangField(field)
        setCurrentLangFieldIndex(index)

        let ls = [];

        if (field === 'question') {
            ls = clue.clue_question_ls || {};
        } else if (field === 'answer') {
            ls = clue.clue_options[index].answer_ls || {};;
        } 

        setLanguages(ls);
    }

    const onSetLs = (langs) => {

        if (currentLangField === 'question') {
            setClue(prev => { return { ...prev, clue_question_ls: langs } })        
        } else if (currentLangField === 'answer') {
            
            setClue((prev) =>{

                prev.clue_options[currentLangFieldIndex].answer_ls = langs;
                return {...prev}

            })
        } 

        setCurrentLangField(null);
        setCurrentLangFieldIndex(null);
        setLanguages(null)
    }

    const onLangPopClose = () => {
        setLanguages(null)
    }

const onUploadImage = (e) => {
    const filename = uploadAsset(e, (fileName) => { setClue(prev => { return { ...prev, clue_image: fileName} }) } );
}

const onRemoveImage = () => {
    setClue(prev => { return { ...prev, clue_image: null } })
}



const uploadAsset = async (e, cb) => {
    let filename = "file.jpg";
    setUploading(true);

    let formData = new FormData();
    formData.append("file", e.target.files[0]);

    axiosClient.post(`${process.env.REACT_APP_API_URL}/upload-file`, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        },
        onUploadProgress: event => {
            console.log(event.loaded)
            setProgress(100 * event.loaded / event.total);
        }
    }).then((res) => {
        //console.log(res);
        cb(res.data.filename);
        setUploading(false);
    })

}

const onSave = () => {

    

    fetch(`${process.env.REACT_APP_API_URL}/clue`, 
        {
            method:'PUT',
            body: JSON.stringify(clue),
            headers:{
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .then(json => {
            console.log(json);
        })
        .catch(err => {
            console.log(err);
        })

}


    return (
        <>

            {!clue && <h1>Loading...</h1>}

            {clue && <div className="p-5">

                <div className="flex flex-row mb-5">

                    <TextField
                        label="Question"
                        fullWidth
                        variant="outlined"
                        name="question"
                        value={clue.clue_question}
                        onChange={onChangeQuestion}
                    />
                    <IconButton aria-label="Add question in other languages">
                        <HiMiniLanguage className='l-icon' onClick={() => onLangPop('question')} />
                    </IconButton>
                </div>
                <div className="flex flex-row mb-5">
                    <FormControl className="!mr-5 w-60">
                        <InputLabel id="answer_type">Question Type</InputLabel>
                        <Select
                            name="question_type"
                            value={clue.clue_type}
                            label="Question Type"
                            onChange={onChangeType}
                        >
                            <MenuItem value={0}>Select Type</MenuItem>
                            <MenuItem value={'question'}>Question</MenuItem>
                            <MenuItem value={'puzzle'}>Puzzle</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                    label="Beacon ID"
                    fullWidth
                    variant="outlined"
                    name="trigger"
                    value={clue.clue_trigger}
                    onChange={onChangeTrigger}
                    />
                </div>
                <hr style={{height:'2px', backgroundColor:'dark-gray'}}/>
                <br/>
                
                <div className="mb-5">
                    <Button className="!mb-5" variant="contained" color="success" onClick={onAddOption}>Add Option</Button>
                    <table className="table-auto border w-full">
                        <thead>
                            <tr>
                                <th className="w-5 text-left p-2">No.</th>
                                <th className="text-left p-2">Options</th>
                                <th className="text-left p-2 w-5">Correct</th>
                                <th className="text-left p-2 w-10">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clue.clue_options && clue.clue_options.map((clue_option, index) => <tr key={index}>
                                <td className="text-center p-2">{String.fromCharCode(65 + index)}</td>
                                <td className="flex flex-row">
                                    <TextField fullWidth label="Answer" variant="outlined" size="small" value={clue_option.answer} onChange={(e) => { onChangeAnswer(e, index)} } />
                                    <IconButton aria-label="Add question in other languages">
                                        <HiMiniLanguage className='l-icon' onClick={() => onLangPop('answer', index)} />
                                    </IconButton>
                                </td>
                                <td><CheckBox onClick={(e) => { onChangeCorrect(e, index) }} checked={clue_option.correct_answer} /></td>
                                <td><Button variant="contained" color="error" className="!ml-5" onClick={(e) => removeOption(e, index)}>Remove</Button></td>
                            </tr>)}
                            
                        </tbody>
                    </table>

                </div>
                <hr style={{height:'2px', backgroundColor:'dark-gray'}}/>
                <br/>
                {clue.clue_image && <img src={`${ASSETS_PATH}/${clue.clue_image}`} width={600}/>}
                <br/>
                {!clue.clue_image && <Button variant="contained" color="success" component="label">
                *Upload Image
                        <input
                            type="file"
                            hidden
                            onChange={onUploadImage}
                        />
                    </Button>}
                {clue.clue_image && <Button variant="contained" color="error" className="!ml-5" onClick={onRemoveImage}>Remove Image</Button>}
                <br/>
                <br/>
                <hr style={{height:'2px', backgroundColor:'dark-gray'}}/>
                <div className="flex flex-row mb-5 mt-5 justify-end">
                    <Button variant="contained" color="success" onClick={onSave}>Save</Button>
                </div>

            </div>}
{uploading && <CircularWithValueLabel progress={progress} />}
            {languages && createPortal(<PopLanguages fieldName={`Space Name`} languages={languages} onSetLs={onSetLs} onLangPopClose={onLangPopClose} />, document.getElementById('modal'))}

        </>
    )
}

export default TreasureClue;
