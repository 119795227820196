import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import axiosClient from '../axios-client';
import { ASSETS_PATH } from "../constants";
import CircularWithValueLabel from '../components/CircularWithValueLabel';
import { formatString } from "../utilities";

const SelfieMask = () => {

    const [mask, setMask] = useState({mask_id:0, mask_name:"mask-name", mask_image:""});
    const { mask_id } = useParams();
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    const onChangeUploadMaskImage = (e) => {
        uploadAsset(e, (fileName) => {
            setMask(d => {
                return { ...d, mask_image: fileName }
            })
        });
    }

    const onRemoveImage = () => {
        setMask(d => {
            return { ...d, mask_image: "" }
        })
    }
    
    const uploadAsset = async (e, cb) => {
        let filename = "file.jpg";
        setUploading(true);

        let formData = new FormData();
        formData.append("file", e.target.files[0]);

        axiosClient.post(`${process.env.REACT_APP_API_URL}/upload-file`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: event => {
                console.log(event.loaded)
                setProgress(100 * event.loaded / event.total);
            }
        }).then((res) => {
            //console.log(res);
            cb(res.data.filename);
            setUploading(false);
        })

    }

    const onSaveMask = () => {

        const payload = { ...mask };

        fetch(`${process.env.REACT_APP_API_URL}/selfie-mask`, {
            method: "PUT",
            body: JSON.stringify(payload),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((res) => res.json())
            .then(json => {
                alert(json.message);
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/selfie-mask/${mask_id}`, { method: 'GET' })
            .then((res) => res.json())
            .then((json) => {
                console.log(json);
                setMask(json[0]);
            });
    }, [])

    return(
        <>
        <div className="content-header pb-6 flex justify-between">
                <div className="content-header-label">Selfie Mask</div>
                <div className="content-header-actions">
                </div>
            </div>
            <div className="content-body">
                {mask.mask_name && <TextField 
                variant="outlined"                
                value={formatString(mask.mask_name)}
                fullWidth
                disabled                
                />}
                <br />
                    <br />
                    {mask.mask_image && <>
                        <img src={`${ASSETS_PATH}/${mask.mask_image}`} style={{ width: '400px' }} />
                        <br />
                        <br />
                    </>
                    }
                    {!mask.mask_image && <Button
                        variant="contained"
                        component="label"
                        style={{ marginRight: "10px" }}
                    >
                        *Upload Image
                        <input
                            type="file"
                            hidden
                            onChange={onChangeUploadMaskImage}
                        />
                    </Button>}

                    {mask.mask_image &&
                        <>
                            <Button variant='contained' onClick={onRemoveImage}>Remove Image</Button>
                        </>}
                    <br />
                    <br />
                    <Button variant='contained' onClick={onSaveMask}>Update Changes</Button>
                                        
                    
            </div>
            {uploading && <CircularWithValueLabel progress={progress} />}
        </>
    )
}

export default SelfieMask;